<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl w-full">

      <h2 class="text-2xl font-medium p-4 border-b-2 border-red-600">
        Organisation Design
      </h2>

      <img
        src="../../assets/img/operating_model.png"
        alt="Organisation Design Banner"
        class="object-fill w-full lg:h-96 h-80 mt-4"
      >

      <div class="p-4">
        <p class="pt-4 pb-2">
          Design organisation to reduce cost, strengthen operational efficiency and
          drive growth & financial performance. Our thoughtful review of your operating
          model go beyond “box and line” organisation structure to define governance,
          accountabilities & responsibilities, processes and talents required.
        </p>
        <p class="pb-4">
          Powered by advanced analytics & data visualisation, MRC Job Evaluation System (JES)
          and Productivity & Financials database, we work together with your key stakeholders
          to define design principles, perform benchmarking and in-dept organisational analysis,
          conduct macro-and-micro level organisation design, review and design workforce
          planning & transition strategy and implementation timeline.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
